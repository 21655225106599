export default function Logo({ size = 32 }: { size?: number }) {
  return (
    <div
      className="rounded-full border-2 border-zinc-900 flex justify-center items-center text-sm select-none"
      style={{
        width: size + "px",
        height: size + "px",
      }}
    >
      <svg
        width="43px"
        height="43px"
        viewBox="0 0 43 43"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="Normando" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect id="Rectangle" fill="#202021" x="0" y="0" width="43" height="43" rx="13"></rect>
          <g id="N" transform="translate(13.2119, 11.4082)" fill="#FFFFFF" fillRule="nonzero">
            <polygon
              id="Path"
              points="17.5341797 21.5917969 13.0224609 21.5917969 4.20410156 6.25488281 4.20410156 21.5917969 0 21.5917969 0 0 4.73144531 0 13.3300781 15.0732422 13.3300781 0 17.5341797 0"
            ></polygon>
          </g>
        </g>
      </svg>
    </div>
  );
}
