import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useHref,
  useLoaderData,
  useNavigate,
  useRouteError,
  type LoaderFunctionArgs,
  type MetaFunction,
  data,
} from "react-router";

import "./styles.css"; // import tailwind as a Vite side effect
import { initUserSession } from "./services/session.server";
import { useNonce } from "./utils/nonce-provider";
import { SessionProvider } from "./services/session";
import { DialogProvider } from "./hooks/use-show-dialog";
// import CommandbarProvider from "./components/command-bar";
// import DockProvider from "./components/dock/dock";
import Logo from "./components/logo";
import { ClientHintCheck, getHints } from "./utils/client-hints";
import { I18nProvider } from "react-aria";
import { RouterProvider } from "react-aria-components";
import { ToastProvider } from "./components/toasts/ToastProvider";
import log from "./services/log";

export const meta: MetaFunction = () => [
  { title: "Da ist etwas schiefgelaufen - Normando" },
  { name: "description", content: "Qualitätsmangement & Arbeitsschutz" },
];

export async function loader({ request }: LoaderFunctionArgs) {
  // initialize the user session and return it
  const { session, headers } = await initUserSession(request);
  const env = {
    VERSION: process.env.VERSION ?? "none",
  };
  return data(
    {
      session,
      env,
      requestInfo: {
        hints: getHints(request),
        path: new URL(request.url).pathname,
      },
    },
    { headers: headers }
  );
}

export default function App() {
  const { session } = useLoaderData<typeof loader>();
  const navigate = useNavigate();
  const nonce = useNonce();
  return (
    <html lang="de">
      <head>
        <ClientHintCheck nonce={nonce} />
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1" />
        <Meta />
        <Links />
      </head>
      <body
        className="flex"
        onDragOver={(ev) => {
          ev.dataTransfer.dropEffect = "none";
          ev.preventDefault();
        }}
        onDrop={(ev) => {
          ev.dataTransfer.dropEffect = "none";
          ev.preventDefault();
        }}
      >
        <SessionProvider session={session}>
          <I18nProvider locale="de-DE">
            <RouterProvider navigate={navigate} useHref={useHref}>
              <ToastProvider>
                <DialogProvider>
                  <Outlet />
                </DialogProvider>
              </ToastProvider>
            </RouterProvider>
          </I18nProvider>
        </SessionProvider>
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  log.error(error, "ErrorBoundary received an error");
  if (isRouteErrorResponse(error)) {
    if (error.status == 422) {
      if (error.data && error.data["message"] && error.data["message"] == "Can't find CSRF token in session.") {
        // ToDo: Do this better and delete session
      }
    }
  }

  return (
    <html lang="DE">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex h-full">
          <div className="flex w-full items-center justify-center px-4 md:w-2/3">
            <div className="w-full max-w-md">
              <h1 className="mb-8 flex items-center justify-center text-2xl font-semibold md:hidden">
                <div className="mr-4 flex h-6 w-6 items-center justify-center rounded-full border-2 border-zinc-900 text-sm">
                  N
                </div>
                <span>Normando</span>
              </h1>
              <div className="md:mx-auto md:w-full md:max-w-xl">
                <h2 className="text-xl font-semibold text-slate-900">Hups, da ist etwas schiefgelaufen</h2>
                <p className="text-sm text-slate-700">
                  Leider ist ein unerwarteter Fehler aufgetreten - das tut uns leid.
                </p>
                <p className="pt-4 text-xs text-slate-500">Wir haben den Fehler zur Untersuchung aufgezeichnet.</p>
                {process.env.NODE_ENV === "development" && (
                  <div className="mt-4 bg-white max-h-72 overflow-y-scroll rounded-md border border-zinc-200 p-4 py-2 text-xs text-slate-500">
                    <p className="whitespace-pre-wrap">{JSON.stringify(error, null, 2)}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hidden w-1/3 items-center justify-start border-l-2 border-gray-100 md:flex">
            <div className="-mx-[30px] p-4">
              <h1 className="flex items-center space-x-2 justify-center text-2xl font-semibold">
                <Logo size={25} />
                <span>Normando</span>
              </h1>
            </div>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}
