import logger from "pino";

const log = logger({
  enabled: true,
  level: "info",
  messageKey: "message",
  errorKey: "error",
  ...(typeof process !== "undefined" &&
    process.env.NODE_ENV !== "production" && {
      transport: {
        target: "pino-pretty",
        options: {
          colorize: true,
          messageKey: "message",
        },
      },
    }),
  formatters: {
    level(label, number) {
      return { level: label };
    },
  },
});

export default log;
